import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { get } from "lodash";
import {
  DASHBOARD,
  ADMIN_DASHBOARD,
  API_URL,
  ADMIN,
  USER_API,
  ARTIST_API,
  EXIT_DEMO,
  LOGGED_OUT,
  WAITING_ROOM,
  FUNDING,
  TUNE_MY_DATA,
  LOGIN,
} from "../../routes/pages/constants";
import styles from "./LoginHeader.module.scss";
import { autoLogout, logout, logoutFromWaitingRoom } from "../../utils/Helper";
import SvgIcons from "../../component/MaterialIcons/SvgIcons";
import { SETTINGS_ICON } from "../../component/MaterialIcons/constants";
import { fetchAccountImg } from "../commonApiCall/AccountImageCall";
import { toast } from "react-toastify";
import request from "../../utils/request";
import StorageService from "../../utils/StorageService";
import ContactPopup from "../ContactPopup";
import AuthTokenService from "../../utils/AuthTokenService";
import { ThemeContext } from "../ThemeManager/ThemeManager";
import Image from "../Image";
import { IMAGE_TYPE } from "../Image/constants";
import Loader from "../Loader";
import { Menu, MenuItem } from "@material-ui/core";
import OverLay from "../OverLay";
import MyAccount from "../../routes/pages/MyAccount/MyAccount";
import EmailPreferences from "../../routes/pages/EmailPreferences/EmailPreferences";
import Dashboard from "../../routes/pages/Dashboard/Dashboard";
import {
  EMAIL_PREFERENCES,
  LINK_ANOTHER_ARTIST,
  MY_ACCOUNT,
  TIMEOUT,
} from "./constants";
import { ReactComponent as NeedHelpIcon } from "../../assets/logos/help-icon.svg";
import { ReactComponent as ClipBoardIcon } from "../../img/streamingData.svg";
import Navigator from "../Navigation/Navigator";
import LinkAnohterArtist from "../../routes/pages/Auth/LinkAnotherArtist";
import NeedHelpPopup from "../NeedHelpPopup/NeedHelpPopup";
import PropTypes from "prop-types";
import { TimeoutPopup } from "../TimeoutPopup/TimeoutPopup";
import base from "base-64";
import { numberWithCommas } from "../../routes/pages/YourAdvance/helper";
import { RenderModal } from "./helper";
import { ARTIST_OUT_RANGE_STATUS_LIST } from "../HorizontalMenu/constants";
import {
  SWITCH_MAX,
  SWITCH_MIN,
} from "../../routes/pages/YourAdvance/constants";

class LoginHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgUrl: undefined,
      role: "",
      isOpen: false,
      isDropOpen: false,
      loading: false,
      artists: [],
      selectedArtist: get(this.props, "selectedArtist", null),
      isDrawerOpen: false,
      drawerComponent: "",
      userEmail: "",
      isOfferReady: false,
      artistOfferReady: {},
      expireModal: false,
      leftTime: TIMEOUT - 1,
      isModalOpen: false,
      fastFlowStep: "",
    };
    this.eventSource = null;
  }

  handleClick = (event) => {
    this.setState({ isDropOpen: event.currentTarget });
  };
  handleDropClose = () => {
    this.setState({
      isDropOpen: null,
    });
  };
  handleClose = () => {
    this.setState({ isOpen: false, isOfferReady: false });
  };
  toggleDrawer = (open) => () => {
    if (ARTIST_OUT_RANGE_STATUS_LIST.indexOf(this.state.fastFlowStep) !== -1) {
      this.setState({
        isDrawerOpen: open,
      });
    }
  };

  handleFastFlowStep = (step) => {
    this.setState({ fastFlowStep: step });
  };

  handleDrawer = (val) => {
    this.setState({ drawerComponent: val, isDrawerOpen: true });
  };

  checkActiveLocation = (value) => {
    return window.location.pathname.includes(value);
  };
  handleContactPopup = () => {
    this.setState({ isOpen: true });
  };

  componentDidMount() {
    this.drawerComponent();
    this.setExpireTime();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userToken !== this.props.userToken) {
      this.setExpireTime();
    }
  }

  storeCustomTokenInJwt = () => {
    AuthTokenService.storeToken(StorageService.get("customToken"));
  };

  exitDemo = () => {
    this.setState({ loading: true });
    const data = {
      method: "POST",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${EXIT_DEMO}`;
    request(requestUrl, data)
      .then(() => {
        window.location.href = get(this.context, "whitelistUrl[0]", "");
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(err.message);
      });
  };

  overLayComponent = () => {
    switch (this.state.drawerComponent) {
      case MY_ACCOUNT:
        return (
          <MyAccount
            toggleDrawer={this.toggleDrawer}
            handleDrawer={this.handleDrawer}
            {...this.props}
          />
        );
      case EMAIL_PREFERENCES:
        return (
          <EmailPreferences toggleDrawer={this.toggleDrawer} {...this.props} />
        );

      case DASHBOARD:
        return (
          <Dashboard
            toggleDrawer={this.toggleDrawer}
            handleDrawer={this.handleDrawer}
            addArtist={fetchAccountImg.bind(this)}
            {...this.props}
          />
        );
      case LINK_ANOTHER_ARTIST:
        return (
          <LinkAnohterArtist
            toggleDrawer={this.toggleDrawer}
            handleDrawer={this.handleDrawer}
            handleFastFlowStep={this.handleFastFlowStep}
            {...this.props}
          />
        );

      default:
        break;
    }
  };

  drawerComponent = () => {
    this.props.drawerComponent && this.props.drawerComponent(this.handleDrawer);
  };

  logOutMenu = () => {
    if (window.location.pathname.includes(WAITING_ROOM)) {
      logoutFromWaitingRoom.call(this, true);
    } else {
      logout();
    }
  };

  handleImageClick = () => {
    const urlArr = get(this.context, "whitelistUrl", []);
    const URL = urlArr[0];
    if (typeof URL !== "undefined") {
      window.location.href = URL;
    }
  };

  setExpireTime = () => {
    let expireDate;
    if (window.location.pathname.includes(ADMIN)) {
      const adminToken = JSON.parse(
        base.decode(StorageService.get("customToken").split(".")[1]),
      );
      expireDate = new Date(get(adminToken, "exp") * 1000);
    } else {
      const obj = JSON.parse(base.decode(AuthTokenService.get().split(".")[1]));
      expireDate = new Date(get(obj, "exp") * 1000);
    }
    const currDate = new Date();
    const diff = (expireDate.getTime() - currDate.getTime()) / 1000 - TIMEOUT;
    if (diff < 0) {
      if (diff > -TIMEOUT) {
        this.setState({ expireModal: true, leftTime: -diff });
        return;
      }
      autoLogout.call(this);
    }
    setTimeout(() => {
      this.setState({ expireModal: true, leftTime: TIMEOUT - 1 });
    }, [diff * 1000]);
  };

  cancelExpireModal = () => {
    this.setState({ expireModal: false });
  };
  renderMenuForSubAdmin = () =>
    this.state.role === "subAdmin" ? (
      <>
        <MenuItem
          data-testid="logOutMenuItem1"
          onClick={() => this.logOutMenu()}
        >
          <li className={styles.menuText}>Logout</li>
        </MenuItem>
      </>
    ) : (
      <>
        {StorageService.get("customToken") && (
          <MenuItem>
            <Link
              to={ADMIN_DASHBOARD}
              className={`${
                this.checkActiveLocation(ADMIN_DASHBOARD) && styles.primary
              } ${styles.menuText}`}
              onClick={this.storeCustomTokenInJwt}
            >
              Admin
            </Link>
          </MenuItem>
        )}
        {!window.location.pathname.includes(ADMIN) && (
          <MenuItem
            onClick={() => this.handleDrawer(DASHBOARD)}
            data-testid="menuItem"
            className={styles.mobileMenuItem}
          >
            <li className={styles.menuText}>Artist Hub</li>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => this.handleDrawer(MY_ACCOUNT)}
          data-testid="menuItem"
        >
          <li
            className={`${
              this.checkActiveLocation(MY_ACCOUNT) && styles.primary
            } ${styles.menuText}`}
          >
            My Account
          </li>
        </MenuItem>
        <MenuItem
          onClick={() => this.handleDrawer(EMAIL_PREFERENCES)}
          data-testid="menuItem"
        >
          <li
            className={`${
              this.checkActiveLocation(EMAIL_PREFERENCES) && styles.primary
            } ${styles.menuText}`}
          >
            Email Preferences
          </li>
        </MenuItem>

        <MenuItem onClick={this.handleContactPopup}>
          <li className={styles.menuText}>Contact Us</li>
        </MenuItem>
        <MenuItem
          data-testid="logOutMenuItem"
          onClick={() => this.logOutMenu()}
        >
          <li className={styles.menuText}>Logout</li>
        </MenuItem>
      </>
    );

  handleModalOpen = () => {
    this.setState({ isModalOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  conditionCheckFunc = () => {
    if (
      !get(this.props, "selectedArtist.offer_approved", false) &&
      !this.props.isDemo &&
      !(
        window.location.pathname.includes(FUNDING) ||
        window.location.pathname.includes(TUNE_MY_DATA)
      )
    ) {
      return true;
    }
    return false;
  };

  handleReturnFunc = () => {
    this.setState({ isModalOpen: false });
    this.props.history.push(FUNDING);
  };

  getMinEstimate = () =>
    get(this.props, "switchDistributorForFunding")
      ? get(this.props, "selectedArtist.estimatedOffer.offer.min") +
        get(this.props, "selectedArtist.estimatedOffer.offer.min") * SWITCH_MIN
      : get(this.props, "selectedArtist.estimatedOffer.offer.min");
  getMaxEstimate = () =>
    get(this.props, "switchDistributorForFunding")
      ? get(this.props, "selectedArtist.estimatedOffer.offer.max") +
        get(this.props, "selectedArtist.estimatedOffer.offer.max") * SWITCH_MAX
      : get(this.props, "selectedArtist.estimatedOffer.offer.max");

  renderEstimatedValue = () => {
    if (get(this.props, "selectedArtist.isFastFlow")) {
      return (
        <>
          {numberWithCommas(this.getMinEstimate())} -{" "}
          {numberWithCommas(this.getMaxEstimate())}
        </>
      );
    } else {
      return numberWithCommas(
        get(
          this.props,
          "selectedArtist.offerStage.defaultOffer.totalAdvance",
          "",
        ),
      );
    }
  };

  render() {
    const profileClassName = window.location.pathname.includes(ADMIN)
      ? styles.profileContainer
      : styles.userProfileContainer;
    return (
      <>
        <div className={styles.headermain}>
          <div className={styles.container}>
            <div className={styles.leftNav}>
              <div
                onClick={this.handleImageClick}
                className={styles.title}
                data-testid="logoClick"
              >
                <Image
                  src={get(this.context, "appLogo")}
                  alt="logo"
                  className={`${styles.bBLogo} ${
                    window.location.pathname.includes(ADMIN)
                      ? styles.adminSideLogo
                      : ""
                  }`}
                  imageType={IMAGE_TYPE.LOGO}
                />
              </div>
              {this.props.isDemo ? (
                <>
                  <span className={styles.demoIndicator}>
                    Viewing artist demo
                  </span>
                  <span
                    className={`${styles.menuText} ${styles.spanLink}`}
                    onClick={this.exitDemo}
                  >
                    Exit demo
                  </span>
                </>
              ) : (
                <p className={styles.headerTitle}>{this.props.headerTitle}</p>
              )}
            </div>
            <div className={styles.rightNav}>
              {!window.location.pathname.includes(ADMIN) &&
                this.conditionCheckFunc() && (
                  <>
                    {!window.location.pathname.includes(LOGGED_OUT) ? (
                      <div className={styles.rightContainerText}>
                        {(get(
                          this.props,
                          "selectedArtist.offerStage.defaultOffer.totalAdvance",
                          false,
                        ) ||
                          get(
                            this.props,
                            "selectedArtist.estimatedOffer.offer.min",
                          )) && (
                          <>
                            <div className={styles.fundingEstimatesText}>
                              Current Estimate
                              <span
                                onClick={this.handleModalOpen}
                                data-testid="modal-info"
                              >
                                <NeedHelpIcon />
                              </span>
                            </div>
                            <div className={styles.fundingEstimatedValue}>
                              ${this.renderEstimatedValue()}
                            </div>
                          </>
                        )}
                      </div>
                    ) : (
                      <Link to={LOGIN} className={styles.loginBtn}>
                        Login
                      </Link>
                    )}
                  </>
                )}
              {this.props.isDemo ? (
                <span className={styles.highlightedBtn} onClick={this.exitDemo}>
                  See Your Real Advance Offers
                </span>
              ) : (
                <>
                  <div className={profileClassName}>
                    {!window.location.pathname.includes(LOGGED_OUT) && (
                      <>
                        <div
                          className={`${styles.settingIconContainer} ${
                            window.location.pathname.includes(ADMIN) &&
                            styles.hideIcon
                          } ${styles.mobileContactIcon}`}
                          data-testid="contact_icon"
                          aria-haspopup="true"
                          onClick={this.handleContactPopup}
                        >
                          <NeedHelpIcon />
                        </div>
                        <div
                          className={`${styles.settingIconContainer} ${
                            !window.location.pathname.includes(ADMIN) &&
                            styles.artistSettingsIcon
                          }`}
                          data-testid="setting_icon"
                          id="demo-positioned-button"
                          aria-haspopup="true"
                          onClick={this.handleClick}
                        >
                          <SvgIcons icon={SETTINGS_ICON} />
                        </div>
                      </>
                    )}
                    <Menu
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={this.state.isDropOpen}
                      open={this.state.isDropOpen}
                      onClose={this.handleDropClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      className={styles.settingMenu}
                      onClick={this.handleDropClose}
                    >
                      {this.renderMenuForSubAdmin()}
                    </Menu>
                  </div>
                </>
              )}
              {!!get(this.props, "isPartnerFlow") && (
                <div className={styles.helperText}>
                  <ClipBoardIcon className={styles.svgIcon} />
                  <div className={styles.text}>
                    Confirmed Offers are Subject to Validation and Final
                    Contract
                  </div>
                </div>
              )}
              {(window.location.pathname.includes(ADMIN) ||
                this.props.isDemo) && (
                <Navigator role={this.state.role} {...this.props} />
              )}
            </div>
          </div>
        </div>

        {window.location.pathname.includes("/marketplace") ? (
          <NeedHelpPopup
            isOpen={this.state.isOpen}
            onRequestClose={this.handleClose}
          />
        ) : (
          <ContactPopup
            isOpen={this.state.isOpen}
            onRequestClose={this.handleClose}
          />
        )}

        <OverLay
          anchor={"left"}
          component={this.overLayComponent()}
          open={this.state.isDrawerOpen}
          onClose={this.toggleDrawer(false)}
          className={
            this.state.drawerComponent === DASHBOARD ? styles.dashboard : null
          }
        />
        {this.state.loading && <Loader />}
        {this.state.expireModal && (
          <TimeoutPopup
            cancelExpireModal={this.cancelExpireModal}
            leftTime={this.state.leftTime}
            setExpireTime={this.setExpireTime}
            {...this.props}
          />
        )}
        <RenderModal
          isModalOpen={this.state.isModalOpen}
          handleCloseModal={this.handleCloseModal}
          selectedArtist={this.props.selectedArtist}
          handleReturnFunc={this.handleReturnFunc}
          getMaxEstimate={this.getMaxEstimate}
          getMinEstimate={this.getMinEstimate}
        />
      </>
    );
  }
}

LoginHeader.contextType = ThemeContext;
export { LoginHeader };

LoginHeader.contextTypes = {
  whitelistUrl: PropTypes.array,
};

export default withRouter(LoginHeader);
